/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '~/jQuery'
import 'jquery-ui'
import 'bootstrap'
import '@/images'
import '@/styles/global.scss'
import { activate as activateReleaseMains } from '@/utils/release-mains'
import { activate as activateSidebar } from '@/utils/sidebar'
import { activate as activateToggleVisibility } from '@/utils/toggle-visibility'

// import { showMoreLines } from './releases';

packLoader(() => {
  activateReleaseMains()
  activateToggleVisibility()
  activateSidebar()
  
  //  // Add event listeners for Show More buttons
  //  document.querySelectorAll('[data-action="show-more-lines"]').forEach(button => {
  //   button.addEventListener('click', event => {
  //     const logId = event.target.dataset.logId;
  //     showMoreLines(logId);
  //   }); 
  //  });
});